import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MediaGrid from '../../components/MediaGrid';
import TicketsGlobal from '../../components/TicketsGlobal';
import LoadingComponent from '../../components/LoadingComponent';
import { Chip } from '@mui/material';

function Payment() {
    let { id } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal  } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Payment View");
        }
    }, [ ]);

    const fetchData = () => {
        axios.post( url + 'api/payment', {
            customer: customer,
            contact: contact,
            phone: phone,
            id: id,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            console.log({provided: response.data})
            setData(response.data);
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Info = () => {
        if (data.hasOwnProperty('payment')) { 
        return (
            <React.Fragment>
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, mb: 4 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                        <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                        <Typography variant="h6" component="h6">{ portal == "supplier" ? data.payment?.buyer_details?.name: data.payment?.seller_details?.name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{portal === "buyer" ? data.payment?.seller_details?.city_details?.name  : data.payment?.buyer_details?.city_details?.name}</span></Typography>
                        { portal === "buyer" ? 
                            <>
                                {!!data.payment?.seller_details?.msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data.payment?.seller_details?.msme} /></Box>}
                            </> 
                            :
                            <>
                                {!!data.payment?.buyer_details?.msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data.payment?.buyer_details?.msme} /></Box>}
                            </>
                        }                    
                    </Box>
                    <Grid container>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1,  borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Payment No</Typography>
                            <Typography variant="body" component="p">{data.payment.payment_no ? data.payment.payment_no : '-'}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderRight: 1, borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Date</Typography>
                            <Typography variant="body" component="p">{data.payment.date ? format(parse(data.payment.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                            <Typography variant="overline" component="p">Total</Typography>
                            <Typography variant="body" component="p">&#8377; {data.payment.total ? data.payment.total : ''}</Typography>
                        </Grid>
                        {!!data?.payment?.narration &&
                            <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider', px: 1, py:1 }}>
                                <Typography variant="overline" component="p">Remarks</Typography>
                                <Typography variant="body" component="p">{data?.payment?.narration || "-"}</Typography>                        
                            </Grid>
                        }
                    </Grid>
                    <Box sx={{ borderColor: 'divider', width: '100%' }}>
                        <MediaGrid dir="payments" images={data.payment.images} />
                    </Box>
                </Paper>
            </React.Fragment>
        )
        } else {
            return (
                <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 1, px: 2, py:2 }}>
                    <Typography variant="overline" component="p">Loading...</Typography>
                </Paper>
            )
        }
    }

    const ItemRow = ({row}) => { 
        let type = "";
        if (row.payment_type == "invoice") {
            type = "Cash / NEFT";
        } else if (row.payment_type == "debitnote") {
            type = "Debit Note";
        } else if (row.payment_type == "invoice_discount") {
            type = "Invoice Discount";
        } 

        function redirectToInvoice(){
            if(row?.payment_type === "invoice"){
                navigate(`/invoice/${row?.invoice?.id}`)
            }
        }

        return (
          <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell align="left" onClick={redirectToInvoice}>{row?.invoice?.voucher_no}</TableCell>
              <TableCell align="left">{type}</TableCell>
              <TableCell align="left">{(row.payment_type == "debitnote" || row.payment_type == "invoice_discount") && row?.total }</TableCell>
              <TableCell align="right">{row.payment_type == "invoice" && row.total}</TableCell>
            </TableRow>
          </React.Fragment>
        );
    }

    const Items = () => {

        const calculateTotal = () => {
            return data?.items?.reduce((acc, item) => {

                if(item?.payment_type === "invoice"){
                    acc.totalAmount =  acc.totalAmount+ parseFloat(item?.total)
                }else if(item?.payment_type === "invoice_discount"){
                    acc.totalDiscount =  acc.totalDiscount+ parseFloat(item?.total)
                }else if(item?.payment_type === "debitnote"){
                    acc.totalDiscount =  acc.totalDiscount+ parseFloat(item?.total)
                }
                
                return acc
            }, {totalAmount: 0, totalDiscount: 0})

            
        }

        const result = calculateTotal()

        return (
            <Box>
                <TableContainer component={Paper} elevation={2} sx={{ mb: 2 }}>
                    <Table stickyHeader aria-label="collapsible table">
                        <TableHead>
                        <TableRow>
                        <TableCell align="left">Invoice No.</TableCell>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">Discount/ Debit note</TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>

                        {data.items?.map((row) => (
                            <ItemRow key={row.id} row={row} />
                        ))}

                        {data.items?.length != 0 &&
                            <TableRow sx={{background: "#F0F4F6"}}>
                                <TableCell colSpan={2} sx={{textAlign: "end",  fontWeight: "bold"}}>Total</TableCell>
                                <TableCell align="left" sx={{ fontWeight: "bold" }}>{result?.totalDiscount?.toFixed(2)}</TableCell>
                                <TableCell align="right" sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}> <small>( Cash / NEFT )</small> {result?.totalAmount?.toFixed(2)}</TableCell>
                            </TableRow>
                        }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Payment">
                <Info />
                <Items />
                <TicketsGlobal 
                    seller={data.payment.seller} buyer={data.payment.buyer} area="clients_vouchers" id={data.payment.id} 
                    createdInAreas={[ 
                        {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                        {label : "Add Button"},
                    ]}
                />
            </Layout>
        }
        </div>
    );
}

export default Payment;
