import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns'
import UploadButton from '../../components/VoucherUploader';
import VoucherSearch from '../../components/VoucherSearch';
import MediaGrid from '../../components/MediaGrid';
import Void from '../../components/Void';
import UploadMedia from '../../components/UploadMedia';
import LoadingComponent from '../../components/LoadingComponent';
import { Button } from '@mui/material';

function Orders() {
    let { status } = useParams();
    const navigate = useNavigate();
    const { url, phone, setPhone, contact, setContact, customer, setCustomer, customerName, setCustomerName, token, setToken, portal } = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
 
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const [currentPage, setCurrentPage] = useState(0);

    const handleChange = (event, newStatus) => {
        setItems([]);
        setLoading(true)
        setHasMore(true);
        setCurrentPage(0);
        return navigate("/orders/" + newStatus)
    };

    useEffect(() => {
        setItems([]);
        setHasMore(true);
        setCurrentPage(0);
        fetchData();
        if(window.cordova) {
            window.cordova.plugins.firebase.analytics.setCurrentScreen("Order list");
        }
    }, [ status ]);

    const fetchData = () => {
        var page = currentPage + 1;
        axios.post( url + 'api/orders?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            status: status,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})
        .then(function (response) {
            setItems((prev) => [...prev, ...response.data.data]);
            setCurrentPage(parseInt(response.data.current_page));
            if(response.data.current_page == response.data.last_page) {
                setHasMore(false);
            }
            setLoading(false);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const Item = (prop) => {
        var voided = 0;
        if(status == 0) {
            if(prop.data.status == 6) {
                voided = 1;
            }
        } else {
            if(prop.data.status == 3) {
                voided = 1;
            }
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? prop.data.buyer_name : prop.data.seller_name }</Typography>
                </Box>
                <Grid container>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Order #</Typography>
                        <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                            {prop.data.voucher_no ? prop.data.voucher_no : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{prop.data.date ? format(parse(prop.data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {prop.data.total ? prop.data.total : ''}</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ borderTop: 1,  borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={prop.data.images} /> 
                </Box> 
                
                    {
                        status == 0 && (portal == "supplier")  &&
                        <Box sx={{margin: "0 10px",}}>
                        <UploadMedia 
                            id={prop.data.id} module="entry"
                            dir="orders" link={url + 'api/media/add'}
                        />
                        </Box>
                    }
                

                { status == 1 && portal == "supplier" &&
                        <Box sx={{ margin: "0 10px" }}>
                            <Button variant="contained"  fullWidth onClick={() => { if(status != 0) { navigate("/order/" + prop.data.id) } }} >View Order</Button>
                        </Box>
                }
                
                
                <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flexDirection: 'row', gap: 1, borderTop: 1, borderColor: 'divider', width: '100%', px: 2, py: 1 }}>
                    { status == 1 && portal == "buyer" &&
                        <Box sx={{ flex:1, flexGrow: 1, }}>
                            <Button variant="contained"  fullWidth onClick={() => { if(status != 0) { navigate("/order/" + prop.data.id) } }} >View Order</Button>
                        </Box>
                    }
                    {portal == "supplier" && prop.data.temp_link == null &&
                        <Box sx={{flexGrow: 1, flex: 1}}><UploadButton title="Invoice" buyer={prop.data.buyer} seller={prop.data.seller} temp_link={prop.data.hasOwnProperty('entry_id') ? prop.data.entry_id : prop.data.id} link_with="entry" voucher_type="invoices" /></Box>
                    }
                    { (prop?.data?.order_status == 1 || prop?.data?.order_status == 2) && 
                        <Box sx={{flex:1, flexGrow: 1, }}>
                            <Button variant="contained"  fullWidth onClick={() => { if(status != 0) { navigate("/order/" + prop.data.id) } }} >View Order</Button>
                        </Box>
                    }                    
                    
                    <Box sx={{flex:1, flexGrow: 1,}}><Void id={prop.data.id} type={ status == 0 ? 'entry' : 'voucher' } voided={prop.data.status != 5 ? 0 : 1 } /></Box>
                </Box>
                
            </Paper>
        )
    }

    return (
        <div>
        {  loading ? 
            <LoadingComponent loading={loading} />
            : 
            <Layout title="Orders" backToHome="true">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={handleChange}>
                        <Tab label="Unproccessed" />
                        <Tab label="Ordered" />
                        <Tab label="Invoiced" />
                        <Tab label="Completed" />
                    </Tabs>

                    <InfiniteScroll scrollThreshold={0.5} dataLength={items.length} next={fetchData} style={{ display: 'flex', flexDirection: 'column' }} inverse={false} hasMore={hasMore} loader={<p style={{ textAlign: 'center', fontSize: '14px' }}>Loading...</p>} endMessage={<p style={{ textAlign: 'center', fontSize: '14px' }}>You have reached end of the list</p>}>
                    {items?.map((row, index) => (
                        <Item key={row.id} data={row}></Item>
                    ))}
                    </InfiniteScroll>
                </Box>
                { portal == "supplier" && 
                    <UploadButton buttonType="fab" title="Order" voucher_type="orders" 
                        createdInAreas={[ 
                            {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                            {label : "Add Button"},
                        ]}
                    />
                }
                <VoucherSearch buttonType="fab" title="Order" voucher_type="orders" />
            </Layout>
        }
        </div>
    );
}

export default Orders;


// DOCS

// Unprocessed Orders :- orders not processed by RS Team
// Ordered :- Processed by RS Team but not invoiced
// Invoiced :- Partially invoiced
// Completed :- Completely Invoiced or over invoiced
 