import React, { useContext } from 'react'
import axios from "axios";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'; // Grid version 1
import Layout from '../../components/Layout';
import { GlobalContext } from '../../contexts/GlobalContext'
import { useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { format, parse } from 'date-fns'
import UploadButton from '../../components/VoucherUploader';
import MediaGrid from '../../components/MediaGrid';
import Void from '../../components/Void';
import UploadMedia from '../../components/UploadMedia';
import LoadingComponent from '../../components/LoadingComponent';
import { Button, Chip, Alert } from '@mui/material';
import { useInfiniteQuery } from 'react-query';
import ErrorMessage from '../../components/ErrorMessage';
import ScrollComponent from '../../components/ScrollComponent';
import FilterForm from '../../components/FilterForm';
import BackToTop from '../../components/BackToTop';
import UnProcessedOrdersFilter from '../../components/UnProcessedOrdersFilter';

function OrdersPage() {
    let { status } = useParams();
    const navigate = useNavigate();
    const { url, phone, contact, customer, token, portal } = useContext(GlobalContext);
    const title = "Orders";

    const fetcher = async (page, pageStatus) => {        
        const response = await axios.post( url + 'api/orders?page=' + page, {
            customer: customer,
            contact: contact,
            phone: phone,
            status: pageStatus,
            creator: portal == "supplier" ? 2 : 3,
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    }

    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        ['orders', status],  
        ({ pageParam = 1 }) => fetcher(pageParam, status),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );

    // console.log({ isLoading, data, error, isError, fetchNextPage, hasNextPage })

    const currentTab = { name: "Orders", status: { 1: "Ordered", 2: "Invoiced", 3: "Completed", 5: "Cancelled" , 99: "All Data",}};

    const Item = ({data}) => {
        
        // console.log({status: data?.status, data});

        let voided = 0;
        if(status == 0) {
            if(data.status == 6) {
                voided = 1;
            }
        } else {
            if(data.status == 3) {
                voided = 1;
            }
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                {
                   portal === "supplier" && customer != data?.seller &&
                   <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1, color: "green" }}>
                            <Typography variant="overline" component="p">{portal == "supplier" && "Supplier from group"}</Typography>
                            <Typography variant="h6" component="h6">{ portal == "buyer" ? data.buyer_name : data.seller_name }</Typography>
                        </Box>
                   </> 
                }
                <Grid container >
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Order #</Typography>
                        <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                            {data.voucher_no ? data.voucher_no : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{data.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : ''}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {data.total ? data.total : ''}</Typography>
                    </Grid>
                </Grid>
                {!!data?.remarks &&
                    <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Remarks</Typography>
                        <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                            {data?.remarks ? data?.remarks  : '-'}
                        </Typography>
                    </Grid>
                }
                <Box sx={{ borderTop: 1,  borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data.images} /> 
                </Box>
                
                    {
                        status == 0 && (portal == "supplier")  && data?.buyer_details?.is_deemed == 0 &&
                        <Box sx={{ borderTop: 1,  borderColor: 'divider', padding: "5px 5px 0 5px", }}>
                            <UploadMedia 
                                id={data.id} module="entry"
                                dir="orders" link={url + 'api/media/add'}
                                refetch={refetch}
                            />
                        </Box>
                    }
                

                    { status == 1 && portal == "supplier" &&  
                        <Box sx={{ borderTop: 1,  borderColor: 'divider', padding: "5px", }}>
                            <Button variant="contained" fullWidth onClick={() => { if(status != 0) { navigate("/order/" + data.id) } }} >View Order</Button>
                        </Box>
                    }
                
                
                {!(data?.status_label == "voided") && !(data?.status_label == "cancelled") &&
                <Box sx={{ display: 'flex', justifyContent:"center", alignContent: "stretch", flexDirection: 'row', gap: 1, borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1 }}>
                    { status == 1 && portal == "buyer" &&
                        <Box sx={{ flex:1, flexGrow: 1,  }}>
                            <Button variant="contained" sx={{height: "100%"}}  fullWidth onClick={() => { if(status != 0) { navigate("/order/" + data.id) } }} >View Order</Button>
                        </Box>
                    }

                    {portal == "supplier" && 
                        <>
                            {(data?.buyer_details?.is_deemed == 0 || (status != 0 || !!data?.voucher_no))  && 
                                <Box sx={{flexGrow: 1, flex: 1, }}>
                                    <UploadButton title="Invoice" buyer={data.buyer} 
                                        seller={(portal === "supplier" && customer != data?.seller) ? customer : data.seller} temp_link={data.hasOwnProperty('entry_id') ? data.entry_id : data.id } link_with="entry"
                                        voucher_type="invoices" refetch={refetch}
                                        seller_name={data?.seller_name} buyer_name={data?.buyer_name}
                                    />
                                </Box> 
                            }
                            {(data?.buyer_details?.is_deemed == 1 && (status == 0 || !data?.voucher?.id)) &&
                            <>
                                <Box sx={{flexGrow: 1, flex: 1, }}>
                                    <Button variant="contained" sx={{height: "100%"}}  fullWidth onClick={() => { console.log("view version is clicked");  navigate("/orders/view_version/" + data.id) }} >View Versions</Button>
                                </Box>
                            </>
                            }
                            {(data?.buyer_details?.is_deemed == 1 && !!data?.finalized_version_id)  && 
                                <Box sx={{flexGrow: 1, flex: 1, }}>
                                    <UploadButton title="Invoice" buyer={data.buyer} 
                                        seller={(portal === "supplier" && customer != data?.seller) ? customer : data.seller} temp_link={data.hasOwnProperty('entry_id') ? data.entry_id : data.id } link_with="entry"
                                        voucher_type="invoices" refetch={refetch}
                                        seller_name={data?.seller_name} buyer_name={data?.buyer_name}
                                    />
                                </Box> 
                            }
                            
                        </>
                    }
                

                    { (data?.order_status == 1 || data?.order_status == 2) && 
                        <Box sx={{flex:1, flexGrow: 1, }}>
                            <Button variant="contained" sx={{height: "100%"}}  fullWidth onClick={() => { if(status != 0) { navigate("/order/" + data.id) } }} >View Order</Button>
                        </Box>
                    }                    
                    {
                        (status == 0 || !data?.voucher_id) && portal === "buyer" && data?.buyer_details?.is_deemed == 1 &&
                        
                        <Box sx={{flexGrow: 1, flex: 1, }}>
                            <Button variant="contained" sx={{height: "100%"}}  fullWidth onClick={() => { console.log("view version is clicked");  navigate("/orders/view_version/" + data.id) }} >View Versions</Button>
                        </Box>
                        
                    }
                    <Box sx={{flex:1, flexGrow: 1, }}>
                        <Void styleObj={{height: "100%"}} refetch={refetch} id={data?.id} type={ status == 0 ? 'entry' : 'voucher' } voided={data.status != 5 ? 0 : 1 } />
                    </Box>
                </Box>
                }
                
            </Paper>
        )
    }

    return (
        <>
        {isError ? <ErrorMessage title={title} message={error?.message} /> :
            <>
            {  isLoading ? 
                <LoadingComponent loading={ isLoading } />
                : 
                <Layout title="Orders" backToHome="true">
                    <Box sx={data?.pages[0]?.data?.length !== 0 ? { borderBottom: 1, borderColor: 'divider' } : {}}>

                        <Tabs variant="scrollable" scrollButtons allowScrollButtonsMobile value={parseInt(status)} onChange={(event, newStatus) => { navigate("/orders/" + newStatus) }}>
                            <Tab label="Unproccessed" />
                            <Tab label="Ordered" />
                            <Tab label="Invoiced" />
                            <Tab label="Completed" />
                            <Tab label="Voided" />
                            <Tab label="Cancelled" />
                        </Tabs>

                        {status == 5 &&
                            <Box sx={{mt:2}}>
                                <Alert severity="info">
                                    Here, you'll see vouchers that have been processed and cancelled.
                                </Alert>
                            </Box>
                        }

                        {status == 0 && data?.pages[0]?.data?.length != 0 &&
                            <Box sx={{mt:2, display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                                <UnProcessedOrdersFilter navigateURL={"/filtered_unprocessed_orders"} />
                            </Box>
                        }

                        <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                    </Box>

                    {status != 0 && <FilterForm currentTab={currentTab} navigateURL={"/filtered_orders"} />}

                    { portal == "supplier" && 
                        <UploadButton buttonType="fab" title="Order" voucher_type="orders" refetch={refetch}
                            createdInAreas={[ 
                                {label : portal == "supplier" ? "Supplier App" : "Buyer App"},
                                {label : "Add Button"},
                            ]}
                        />
                    }

                    { portal === "supplier" ? <BackToTop /> : <BackToTop alone={true} />}
                    
                </Layout>
            }
            </>
        }
        </>
    );
}

export default OrdersPage;


// DOCS

// Unprocessed Orders :- orders not processed by RS Team
// Ordered :- Processed by RS Team but not invoiced
// Invoiced :- Partially invoiced
// Completed :- Completely Invoiced or over invoiced
 