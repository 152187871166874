import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { HashRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Switcher from "./pages/Switcher";
import SupplierDashboard from "./pages/supplier/Dashboard";
import SupplierCheckins from "./pages/supplier/Checkins";
import SupplierReturns from "./pages/supplier/Returns";
import SupplierCatalog from "./pages/supplier/Catalog";
import SupplierCatalogManage from "./pages/supplier/CatalogManage";
import SupplierForms from "./pages/supplier/SupplierForms";
import SupplierFormsManage from "./pages/supplier/SupplierFormsManage";
import Tickets from "./pages/common/Tickets";
import Ticket from "./pages/common/Ticket";
import Checkins from "./pages/common/Checkins";
import Orders from "./pages/common/Orders";
import Order from "./pages/common/Order";
import CustomOrders from "./pages/common/CustomOrders";
import Invoices from "./pages/common/Invoices";
import Invoice from "./pages/common/Invoice";
import DebitNotes from "./pages/common/DebitNotes";
import DebitNote from "./pages/common/DebitNote";
import CreditNotes from "./pages/common/CreditNotes";
import CreditNote from "./pages/common/CreditNote";
import Return from "./pages/common/Return";
import Ledgers from "./pages/common/Ledgers";
import Ledger from "./pages/common/Ledger";
import Chat from "./pages/common/Chat";
import ChatChannel from "./pages/common/ChatChannel";
import Payments from "./pages/common/Payments";
import Payment from "./pages/common/Payment";
import BuyerDashboard from "./pages/buyer/Dashboard";
import BuyerReturns from "./pages/buyer/Returns";
import BuyerCatalog from "./pages/buyer/Catalog";
import BuyerCatalogCategories from "./pages/buyer/CatalogCategories";
import BuyerCatalogProducts from "./pages/buyer/CatalogProducts";
import BuyerPlans from "./pages/buyer/Plans";
import BuyerPlan from "./pages/buyer/Plan";
import Profile from "./pages/common/Profile";
import { GlobalContextProvider } from './contexts/GlobalContext'
import './components/Scrollbar.css';
import Transports from './pages/common/Transports';
import SupportManagers from './pages/common/SupportManagers'; 
import LedgerStatements from './pages/common/LedgerStatements';
import OtherTransactions from './pages/common/OtherTransactions';
import FinancialStatement from './pages/common/FinancialStatement';
import LedgerStatementView from './pages/common/LedgerStatementView';
import ReportsCategory from './pages/common/ReportsCategory';
import PendingOrders from './pages/common/reports/PendingOrders';
import UnfullfilledOrdersByDesign from './pages/common/reports/UnfullfilledOrdersByDesign';
import UnclosedOverDeliveryDate from './pages/common/reports/UnclosedOverDeliveryDate';
import OrderReport from './pages/common/reports/OrderReport';
import DueReport from './pages/common/reports/DueReport';
import VisitPerformance from './pages/common/reports/VisitPerformance';
import TermsBySupplier from './pages/common/reports/TermsBySupplier';
import TermsByBuyer from './pages/common/reports/TermsByBuyer';
import FilteredCheckIns from './pages/common/filteredData/FilteredCheckIns';
import { FilteredDataProvider } from './contexts/FilteredDataContext';
import CheckInsPage from './pages/common/CheckInsPage';
import { QueryClient, QueryClientProvider } from 'react-query';
import BuyerPlansPage from './pages/buyer/PlansPage';
import FilteredPlans from './pages/common/filteredData/FilteredPlans';
import OrdersPage from './pages/common/OrdersPage';
import FilteredOrders from './pages/common/filteredData/FilteredOrders';
import CustomOrdersPage from './pages/common/CustomOrdersPage';
import FilteredCustomOrders from './pages/common/filteredData/FilteredCustomOrders';
import PaymentsPage from './pages/common/PaymentsPage';
import FilteredPayments from './pages/common/filteredData/FilteredPayments';
import TicketsPage from './pages/common/TicketsPage';
import FilteredTickets from './pages/common/filteredData/FilteredTickets';
import InvoicesPage from './pages/common/InvoicesPage';
import FilteredInvoices from './pages/common/filteredData/FilteredInvoices';
import CreditNotesPage from './pages/common/CreditNotesPage';
import DebitNotesPage from './pages/common/DebitNotesPage';
import FilteredCreditNotes from './pages/common/filteredData/FilteredCreditNotes';
import FilteredDebitNotes from './pages/common/filteredData/FilteredDebitNotes';
import PaymentPlanner from './pages/buyer/PaymentPlanner';
import DeemedCustomersView from './pages/common/DeemedCustomersView';
import FilteredUnProcessedOrders from './pages/common/filteredData/FilteredUnProcessedOrders';

const queryClient = new QueryClient();

const darkTheme = createTheme({
  palette: {
    // mode: "light",
    background: {
      default: "#f0f4f6", // "#f0f4f6"
      paper: "#fff", // 
    },
    primary: {
      main: "#044B7F", // #1d3557
    },
    secondary: {
      main: '#044B7F', // #5393ff (fixed = "#1d3557")
    },
  },
  spacing: 5,
  typography: {
    caption: {
      fontSize: 10,
      lineHeight: 1.5,
    },
    body: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.5,
    },
    overline: {
      fontSize: 10,
      lineHeight: 1.5,
    },
    button: {
      fontSize: 10,
      lineHeight: 1.5,
    },
  },
  components: {
    CssBaseline: {
      styleOverrides: `
        html {
          padding: env(safe-area-inset);
        }
        body {
          padding: env(safe-area-inset);
        }
        @supports (padding: max(0px)) {
          html {
            padding: max(env(safe-area-inset, 0px));
          }
          body {
            padding: max(env(safe-area-inset, 0px));
          }
        }
      `,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient} >
      <ThemeProvider theme={darkTheme}>
        <GlobalContextProvider>
          <FilteredDataProvider>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/switcher" element={<Switcher />} />
              <Route path="/supplier" element={<SupplierDashboard />} />
              <Route path="/supplier/checkins/:status" element={<SupplierCheckins />} />
              <Route path="/supplier/returns/:status" element={<SupplierReturns />} />
              <Route path="/supplier/catalog" element={<SupplierCatalog />} />
              <Route path="/supplier/catalog/manage/:id/:status" element={<SupplierCatalogManage />} />
              <Route path="/supplier/forms/" element={<SupplierForms />} />
              <Route path="/supplier/forms/manage/:id" element={<SupplierFormsManage />} />

              <Route path="/checkins/:status" element={<CheckInsPage />} />
              <Route path="/orders/:status" element={<OrdersPage />} />
              <Route path="/customorders/:status" element={<CustomOrdersPage />} />
              <Route path="/order/:id" element={<Order />} />            
              <Route path="/order/view/:id" element={<Order forceView={true} />} />
              <Route path="/invoices/:status" element={<InvoicesPage />} />
              <Route path="/invoice/:id" element={<Invoice />} />
              <Route path="/debitnotes/:status" element={<DebitNotesPage />} />
              <Route path="/debitnote/:id" element={<DebitNote />} />
              <Route path="/creditnotes/:status" element={<CreditNotesPage />} />
              <Route path="/creditnote/:id" element={<CreditNote />} />
              <Route path="/payments/:status" element={<PaymentsPage />} />
              <Route path="/payment/:id" element={<Payment />} />
              <Route path="/return/:type/:status" element={<Return />} />
              <Route path="/tickets/:status" element={<TicketsPage />} />
              <Route path="/ticket/:id" element={<Ticket />} />
              <Route path="/ledgers/" element={<Ledgers />} />
              <Route path="/ledger/:id" element={<Ledger />} />
              <Route path="/chat/" element={<Chat />} />
              <Route path="/chat/channel/:id" element={<ChatChannel />} />
              <Route path="profile/" element={<Profile />} />

              <Route path="/buyer" element={<BuyerDashboard />} />
              <Route path="/buyer/plans/" element={<BuyerPlansPage />} />
              <Route path="/buyer/plan/:id" element={<BuyerPlan />} />
              <Route path="/buyer/returns/:status" element={<BuyerReturns />} />
              <Route path="/buyer/catalog/:status" element={<BuyerCatalog />} />
              <Route path="/buyer/catalog/categories/:supplier" element={<BuyerCatalogCategories />} />
              <Route path="/buyer/catalog/products/:category/:supplier" element={<BuyerCatalogProducts />} />
              <Route path="/support/transports" element={<Transports/>} />
              <Route path="/support/managers" element={<SupportManagers/>} />
              <Route path="/ledgerstatements" element={<LedgerStatements/>} />
              <Route path="/othertransactions" element={<OtherTransactions/>} />
              <Route path="/financial_statement" element={<FinancialStatement/>} />
              <Route path="/ledgerstatement/:id" element={<LedgerStatementView/>} />
              <Route path="/reportscategory" element={<ReportsCategory />} />
              <Route path="/reports/pending_orders" element={<PendingOrders />} />
              <Route path="/reports/unfullfilled_orders_by_design" element={<UnfullfilledOrdersByDesign />} />
              <Route path="/reports/unclosed_over_delivery_date" element={<UnclosedOverDeliveryDate />} />
              <Route path="/reports/order_report" element={<OrderReport />} />
              <Route path="/reports/due_report" element={<DueReport />} />
              <Route path="/reports/visit_performance" element={<VisitPerformance />} />
              <Route path="/reports/terms_by_supplier" element={<TermsBySupplier />} />
              <Route path="/reports/terms_by_buyer" element={<TermsByBuyer />} />

              <Route path="/orders/view_version/:id" element={<DeemedCustomersView />} />


              {/* <Route path="/checkinspage/:status" element={<CheckInsPage />} />              
              <Route path="/buyer/planspage/" element={<BuyerPlansPage />} />
              <Route path="/orderspage/:status" element={<OrdersPage />} />              
              <Route path="/customorderspage/:status" element={<CustomOrdersPage />} />              
              <Route path="/paymentspage/:status" element={<PaymentsPage />} />              
              <Route path="/ticketspage/:status" element={<TicketsPage />} />              
              <Route path="/invoicesPage/:status" element={<InvoicesPage />} />              
              <Route path="/creditnotespage/:status" element={<CreditNotesPage />} />
              <Route path="/debitnotespage/:status" element={<DebitNotesPage />} /> */}
 
              <Route path="/filtered_checkins" element={<FilteredCheckIns />} /> 
              <Route path="/filtered_plans" element={<FilteredPlans />} />
              <Route path="/filtered_orders" element={<FilteredOrders />} />
              <Route path="/filtered_unprocessed_orders" element={<FilteredUnProcessedOrders />} />
              <Route path="/filtered_customorders" element={<FilteredCustomOrders />} />
              <Route path="/filtered_payments" element={<FilteredPayments />} />
              <Route path="/filtered_tickets" element={<FilteredTickets />} />
              <Route path="/filtered_invoices" element={<FilteredInvoices />} />
              <Route path="/filtered_creditnotes" element={<FilteredCreditNotes />} />
              <Route path="/filtered_debitnotes" element={<FilteredDebitNotes />} />

              <Route path="/buyer/payment_planner" element={<PaymentPlanner />} />             

            </Routes>       
          </FilteredDataProvider>  
        </GlobalContextProvider>
      </ThemeProvider>
      </QueryClientProvider>
  );
}

export default App;
