import { Alert, AlertTitle, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { format, parse } from "date-fns";
import { useContext } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import BackToTop from "../../../components/BackToTop";
import ErrorMessage from "../../../components/ErrorMessage";
import Layout from "../../../components/Layout";
import LoadingComponent from "../../../components/LoadingComponent";
import MediaGrid from "../../../components/MediaGrid";
import ScrollComponent from "../../../components/ScrollComponent";
import UploadMedia from "../../../components/UploadMedia";
import Void from "../../../components/Void";
import UploadButton from "../../../components/VoucherUploader";
import { FilterDataContext } from "../../../contexts/FilteredDataContext";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function FilteredUnProcessedOrders(){
    const navigate = useNavigate();

    const { filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus,  } = useContext(FilterDataContext);
    
    const { url, customer, token, portal, contact  } = useContext(GlobalContext);

    const title = "Filtered Unprocessed Orders"

    console.log({filterDataBuyer, filterDataSupplier, filterDataFromDate,  filterDataToDate,  filterDataStatus, })

    const fetcher = async (page) => {        
        const response = await axios.post( url + 'api/entries/search?page=' + page, {
            customer: customer,
            contact: contact,
            creator: portal == "supplier" ? 2 : 3,
            seller: portal === "supplier" ? customer : filterDataSupplier?.id,
            buyer: portal === "buyer" ? customer : filterDataBuyer?.id,
            from_date: filterDataFromDate,
            to_date: filterDataToDate,
            status: 0,
            voucher_type: "orders",
        }, { headers: { Authorization: `Bearer ${token}` }})

        return response.data
    } 
        
    const { isLoading, data, error, isError, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery( 
        'filteredUnProcessedOrders',  
        ({ pageParam = 1 }) => fetcher(pageParam),
        {
          getNextPageParam: (lastPageData) => { 
            if(lastPageData?.current_page < lastPageData.last_page){
                return lastPageData?.current_page + 1
            }else{
                return undefined
            } 
          },
          refetchOnWindowFocus: false,
        }
    );
    
    const Item = ({data}) => {

        let voided = 0;
        if(data?.status == 6) {
            voided = 1;
        }

        return (
            <Paper sx={{ border: 1, borderColor: 'divider', cursor: 'pointer', width: '100%', maxWidth: '100%', textAlign: 'center', mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1 }}>
                    <Typography variant="overline" component="p">{portal == "supplier" ? "Buyer" : "Supplier"}</Typography>
                    <Typography variant="h6" component="h6">{ portal == "supplier" ? data.buyer_name : data.seller_name }, <span style={{ fontWeight: 400, fontSize: "0.9em", color: "gray"}}>{ portal == "supplier" ? data.buyer_city_details?.name : data.seller_city_details?.name }</span></Typography>
                    {
                        portal === "buyer" && !!data?.supplier_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.supplier_msme} /></Box>
                    }
                    {
                        portal === "supplier" && !!data?.buyer_msme &&  <Box><Chip variant="outlined" color="primary" size="small" label={data?.buyer_msme} /></Box>
                    }
                </Box>
                {
                   portal === "supplier" && customer != data?.seller &&
                   <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', px: 2, py:1, color: "green" }}>
                            <Typography variant="overline" component="p">{portal == "supplier" && "Supplier from group"}</Typography>
                            <Typography variant="h6" component="h6">{ portal == "buyer" ? data.buyer_name : data.seller_name }</Typography>
                        </Box>
                   </> 
                }
                <Grid container >
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Order #</Typography>
                        <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                            {data?.voucher_no ? data.voucher_no : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ borderRight: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Date</Typography>
                        <Typography variant="body" component="p">{!!data?.date ? format(parse(data.date, 'yyyy-MM-dd', new Date()), 'dd MMM yyyy') : 'Not Available'}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Total</Typography>
                        <Typography variant="body" component="p">&#8377; {!!data?.total ? data?.total : '-'}</Typography>
                    </Grid>
                </Grid>
                {!!data?.remarks &&
                    <Grid item xs={12} sx={{ borderTop: 1, borderColor: 'divider', px: 1, py:1 }}>
                        <Typography variant="overline" component="p">Remarks</Typography>
                        <Typography variant="body" component="p" style={{wordWrap: "break-word"}}>
                            {data?.remarks || 'Not Available'}
                        </Typography>
                    </Grid>
                }
                <Box sx={{ borderTop: 1,  borderColor: 'divider', width: '100%' }}>
                    <MediaGrid dir="orders" images={data.images} /> 
                </Box>
                {
                    (portal == "supplier")  && data?.buyer_details?.is_deemed == 0 &&
                    <Box sx={{ borderTop: 1,  borderColor: 'divider', padding: "5px 5px 0 5px", }}>
                        <UploadMedia 
                            id={data.id} module="entry"
                            dir="orders" link={url + 'api/media/add'}
                            refetch={refetch}
                        />
                    </Box>
                }
                <Box sx={{ display: 'flex', justifyContent:"center", alignContent: "stretch", flexDirection: 'row', gap: 1, borderTop: 1, borderColor: 'divider', width: '100%', px: 1, py: 1 }}>                        

                    {portal == "supplier" && 
                        <>
                            {(data?.buyer_details?.is_deemed == 0 || (!!data?.voucher_no))  && 
                                <Box sx={{flexGrow: 1, flex: 1, }}>
                                    <UploadButton title="Invoice" buyer={data.buyer} 
                                        seller={(portal === "supplier" && customer != data?.seller) ? customer : data.seller} temp_link={data.hasOwnProperty('entry_id') ? data.entry_id : data.id } link_with="entry"
                                        voucher_type="invoices" refetch={refetch}
                                        seller_name={data?.seller_name} buyer_name={data?.buyer_name}
                                    />
                                </Box> 
                            }
                            
                            {(data?.buyer_details?.is_deemed == 1 && (!data?.voucher?.id)) &&
                            <>
                                <Box sx={{flexGrow: 1, flex: 1, }}>
                                    <Button variant="contained" sx={{height: "100%"}}  fullWidth onClick={() => { navigate("/orders/view_version/" + data.id) }} >View Versions</Button>
                                </Box>
                            </>
                            }
                            {(data?.buyer_details?.is_deemed == 1 && !!data?.finalized_version_id)  && 
                                <Box sx={{flexGrow: 1, flex: 1, }}>
                                    <UploadButton title="Invoice" buyer={data.buyer} 
                                        seller={(portal === "supplier" && customer != data?.seller) ? customer : data.seller} temp_link={data.hasOwnProperty('entry_id') ? data.entry_id : data.id } link_with="entry"
                                        voucher_type="invoices" refetch={refetch}
                                        seller_name={data?.seller_name} buyer_name={data?.buyer_name}
                                    />
                                </Box> 
                            }
                        </>
                    }
                                    
                    {portal === "buyer" && (!data?.voucher_id) && data?.buyer_details?.is_deemed == 1 &&
                        <Box sx={{flexGrow: 1, flex: 1, }}>
                            <Button variant="contained" sx={{height: "100%"}}  fullWidth onClick={() => { console.log("view version is clicked");  navigate("/orders/view_version/" + data.id) }} >View Versions</Button>
                        </Box>
                    }
                    <Box sx={{flex:1, flexGrow: 1, }}>
                        <Void styleObj={{height: "100%"}} refetch={refetch} id={data?.id} type={'entry'} voided={data.status != 5 ? 0 : 1 } />
                    </Box>
                </Box>
                
            </Paper>
        )
    }

    if(!filterDataBuyer && !filterDataSupplier){
        navigate(-1, { replace: true })
        return null;
    }

    return(
        <>       
            {isError ? <ErrorMessage title={title} message={error?.message} /> :
                <>            
                    {isLoading ? 
                        <LoadingComponent loading={isLoading} />
                        : 
                        <Layout title={title} backToTop={true} >                
                            <Box>
                                <Alert severity="success" >
                                    <AlertTitle sx={{fontSize: "0.5 em"}}>
                                        <Typography variant="subtitle2">
                                            Results {portal === "buyer" ? filterDataSupplier && `for ${(filterDataSupplier?.name?.toUpperCase())}` : filterDataBuyer && `for ${(filterDataBuyer?.name?.toUpperCase())}`}
                                        </Typography> 
                                    </AlertTitle>
                                    {(filterDataFromDate && filterDataToDate) && 
                                        <Typography variant="subtitle2">
                                            {` From: ${format(new Date(filterDataFromDate), 'dd/MM/yyyy')} To: ${format(new Date(filterDataToDate), 'dd/MM/yyyy')}`}
                                        </Typography>
                                    }
                                </Alert>                    
                            </Box>                                    
                            <ScrollComponent data={data} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} Item={Item}  />
                            <BackToTop alone={true} />
                        </Layout>
                    }
                </>
            } 
        </>       
    )
}