import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, Grid, InputLabel, TextField, Typography, } from '@mui/material'
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { GlobalContext } from '../contexts/GlobalContext'; 
import { FilterDataContext } from '../contexts/FilteredDataContext';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import SearchIcon from '@mui/icons-material/Search';


function UnProcessedOrdersFilter({navigateURL}) {

    const navigate = useNavigate()

    const { url, token, portal, customer  } = useContext(GlobalContext);
    const { setFilterDataBuyer, setFilterDataSupplier,  setFilterDataFromDate, setFilterDataToDate,
          clearContextState, setFilterDataStatus, setFilterDataQuery } = useContext(FilterDataContext);

    const [ modalOpen, setModalOpen ] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedBuyer, setSelectedBuyer] = useState("")
    const [selectedSupplier, setSelectedSupplier] = useState("")
    const [selectedFromDate, setSelectedFromDate] = useState("")
    const [selectedToDate, setSelectedToDate] = useState("") 

    const [buyerOptions, setBuyerOptions] = useState([])
    const [supplierOptions, setSupplierOptions] = useState([]);


    function clearFilterData(){
        setLoading(true);
        setModalOpen(false);
        setSelectedBuyer("")
        setSelectedSupplier("");
        setSelectedFromDate();
        setSelectedToDate();
        setBuyerOptions([]);
        setSupplierOptions([]);
        setLoading(false);
    }

    function setGlobalState() {
        portal === "supplier" ? setFilterDataBuyer(selectedBuyer) : setFilterDataBuyer(customer);
        portal === "buyer" ? setFilterDataSupplier(selectedSupplier) : setFilterDataSupplier(customer);
        selectedFromDate ? setFilterDataFromDate(format(new Date(new Date(selectedFromDate).setHours(0,0,0,0)), 'yyyy-MM-dd HH:mm:ss')) :  setFilterDataFromDate("");
        selectedToDate ? setFilterDataToDate(format(new Date(new Date(selectedToDate).setHours(23,59,59,999)), 'yyyy-MM-dd HH:mm:ss')) :  setFilterDataToDate("");
    }
    
    function handleSubmit(){
        if((selectedFromDate && selectedToDate) || (!selectedFromDate && !selectedToDate)){
            setLoading(true);
            clearContextState()
            setGlobalState()
            clearFilterData();
            setLoading(false);
            navigate(navigateURL)
        }
    }

    const fetchBuyers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "buyer",
                supplier: customer,
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setBuyerOptions(response.data)
            })
            .catch(function (error) {
                console.log({error});
            });
        }
    }

    const fetchSuppliers = (query) => {
        if (query.length) {
            axios.post( url + 'api/admin/customers', {
                mode: "supplier",
                buyer: customer,
                search_term: query
            }, { headers: { Authorization: `Bearer ${token}` }})
            .then(function (response) {
                setSupplierOptions(response.data)
            })
            .catch(function (error) {
                console.log({error});
            });
        }
    }

    function isReadyToSubmit() {
        if(selectedFromDate && selectedToDate){
            return true
        }else if(!selectedFromDate && !selectedToDate){
            if(portal === "buyer" && !!selectedSupplier?.id){
                return true                
            }else if(portal === "supplier" && !!selectedBuyer?.id){
                return true
            }else{
                return false
            }           
        }
        return false
    }


    return (
    <>

        <Button variant="contained" color="primary" onClick={() => {setModalOpen(true)}} size={"medium"} startIcon={<SearchIcon/>}>
            <Typography variant="button">Search</Typography>
        </Button>

        <Dialog onClose={clearFilterData} open={modalOpen}>
            <DialogTitle>
                <Typography variant="subtitle2" sx={{fontWeight: "bold"}}>Search Unprocessed Orders</Typography>
            </DialogTitle>
            <DialogContent sx={{ width: '80vw', maxWidth: '300px', }}>
                
                {
                    portal === "supplier" ? 
                    (
                        <>
                            <InputLabel shrink>Buyer </InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                
                                options={ buyerOptions }
                                size="small"
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(event, newInputValue) => { setBuyerOptions([]); fetchBuyers(newInputValue) }}
                                value={selectedBuyer}
                                onChange={(event, newValue) => {
                                    setSelectedBuyer(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required={false} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />
                        </>
                    )
                    :
                    (
                        <>
                            <InputLabel shrink>Supplier </InputLabel>
                            <Autocomplete sx={{ mb: 2 }}
                                
                                options={ supplierOptions }
                                size="small"
                                getOptionLabel={(option) => option.name ? option.name : '' }
                                onInputChange={(e, newInputValue) => { setSupplierOptions([]); fetchSuppliers(newInputValue) }}
                                value={selectedSupplier}
                                onChange={(e, newValue) => {
                                    setSelectedSupplier(newValue)
                                }}
                                renderInput={(params) => <TextField {...params} required={false} />}
                                isOptionEqualToValue={(option, value) => { 
                                    if(!value){
                                        return true
                                    }else{
                                        return option?.id == value?.id
                                    }
                                }}
                            />
                        </>
                    )
                }
                
                <Grid container spacing={2} sx={{mb:2}}>
                    <Grid item xs={12}>
                        <InputLabel shrink>From Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedFromDate}
                        size="small"
                        onChange = {(event) => {setSelectedFromDate(event?.target?.value)}}
                        InputLabelProps={{ shrink: true }} 
                        helperText={(selectedToDate && !selectedFromDate ) ? "Please enter From Date" : "" }
                        FormHelperTextProps={{error: true}}
                        required={true}
                        InputProps={{
                            inputProps: {
                                max: selectedToDate || "", 
                            },
                            }}
                        />
                        
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel shrink>To Date</InputLabel>
                        <TextField type="date"  variant="outlined" fullWidth 
                        value={selectedToDate}
                        size="small"
                        onChange = {(event) => { setSelectedToDate(event?.target?.value) }}
                        InputLabelProps={{ shrink: true }}
                        helperText={(selectedFromDate && !selectedToDate ) ? "Please enter To Date" : "" }
                        FormHelperTextProps={{error: true}}
                        required={true}
                        InputProps={{
                            inputProps: {
                                min: selectedFromDate || "",
                            },
                            }}
                        />                                    
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 4 }}>
                    <LoadingButton loading={loading} variant="contained" color={"error"} onClick={clearFilterData} fullWidth>Clear Filter</LoadingButton>
                    <LoadingButton loading={loading} disabled={!isReadyToSubmit()} variant="contained" onClick={handleSubmit} fullWidth>Filter</LoadingButton>                    
                </Box>
            </DialogContent>
        </Dialog>
    </>
    )
}

export default UnProcessedOrdersFilter